import React from "react";
import styled from "styled-components";
import { Flex } from "../Box";
import StyledToggle, { Input, Handle } from "./StyledToggle";
import { ToggleProps, scales } from "./types";

const StyledToggleWrap = styled(StyledToggle)`
  position: absolute;
  top: 7px;
  right: 7.5px;
  background: #fff;
  border-radius: 20px;
  @media screen and (max-width: 1199px) {
    top: 4px;
  }
`;

const Toggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  checked,
  defaultColor = "input",
  checkedColor = "success",
  scale = scales.LG,
  startIcon,
  endIcon,
  disabled,
  ...props
}) => {
  const isChecked = !!checked;

  return (
    <StyledToggleWrap
      disabled={disabled}
      $checked={isChecked}
      $checkedColor={checkedColor}
      $defaultColor={defaultColor}
      scale={scale}
      {...props}
    >
      <Input disabled={disabled} checked={checked} scale={scale} type="checkbox" />
      {startIcon && endIcon ? (
        <>
          <Handle scale={scale}>
            <Flex height="100%" alignItems="center" justifyContent="center">
              {checked ? endIcon(checked) : startIcon(!checked)}
            </Flex>
          </Handle>
          <Flex width="100%" height="100%" justifyContent="space-around" alignItems="center">
            {startIcon()}
            {endIcon()}
          </Flex>
        </>
      ) : (
        <Handle scale={scale} />
      )}
    </StyledToggleWrap>
  );
};

export default Toggle;
