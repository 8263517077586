import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'
import { ASSET_CDN } from './endpoints'

export const DEFAULT_META: PageMeta = {
  title: 'BTCFund Swap',
  description: 'The most popular AMM on BSC by user count!',
  image: `/hero.png`,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/mint-token': { title: t('Mint Tokens List') },
      '/swap': { basePath: true, title: t('Exchange'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/add': { basePath: true, title: t('Add Liquidity'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/remove': { basePath: true, title: t('Remove Liquidity'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/liquidity': { title: t('Liquidity'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/find': { title: t('Import Pool') },
      '/farms': { title: t('Farms'), image: `${ASSET_CDN}/web/og/farms.jpg` },
      '/farms/auction': { title: t('Farm Auctions'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/pools': { title: t('Pools'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/info/pairs': {
        title: `${t('Pairs')} - ${t('Info')}`,
        description: 'View statistics for BTCFund Swap exchanges.',
        image: `${ASSET_CDN}/web/og/hero.png`,
      },
      '/info/tokens': {
        title: `${t('Tokens')} - ${t('Info')}`,
        description: 'View statistics for BTCFund Swap exchanges.',
        image: `${ASSET_CDN}/web/og/hero.png`,
      },
      '/nfts': { title: t('NFT Marketplace'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/nfts/collections': { basePath: true, title: t('Collections'), image: `${ASSET_CDN}/web/og/hero.png` },
      '/profile': { basePath: true, title: t('Profile') },
    },
    defaultTitleSuffix: t('BTCFund Swap'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
