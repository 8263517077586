import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | BNB-20',
  defaultTitle: 'BNB-20',
  description:
    'BNB20 is a new token standard created on the Binance network. This standard is designed to provide flexibility, security, and scalability for blockchain developers.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@BNB-20',
    site: '@BNB-20',
  },
  openGraph: {
    title: 'BNB-20',
    description:
      'BNB20 is a new token standard created on the Binance network. This standard is designed to provide flexibility, security, and scalability for blockchain developers.',
    images: [{ url: 'https://bnb20.co/logo.png' }],
  },
}
