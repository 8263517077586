import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: SUISSE_REGULAR;
    src: url("/fonts/Nippo-Regular.otf");
  }
  @font-face {
    font-family: SUISSE_MEDIUM;
    src: url("/fonts/Nippo-Regular.otf");
  }
  @font-face {
    font-family: SUISSE_BOLD;
    src: url("/fonts/Nippo-Medium.otf");
  }
  @font-face {
    font-family: SUISSE_BLACK;
    src: url("/fonts/Nippo-Bold.otf");
  }

  * {
    font-family: SUISSE_REGULAR;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    scroll-behavior: smooth;
    background : rgba(13,15,17);
  }
  
  body {
    background : rgba(13,15,17);
    font-family: SUISSE_REGULAR;
    font-size: 1.4rem;
    line-height: 1.75rem;
    color: #FFF;
    overflow-y : hidden;
    overflow-x: hidden;
    img {
      height: auto;
      max-width: 100%;
    }
    @media screen and (max-width : 1199px) {
      overflow-y : auto;
    }
  }

  /* CIRCLE DYNAMIC */
  .fancy-carousel-element {
    box-shadow: none !important;
    transition : 60s transform !important;
    will-change: transform;
  }
  .fancy-carousel {
    transition : 60s transform !important;
    will-change: transform;
  }

  @media screen and (max-width: 1199px) {
    .wrapContent {
      padding : 80px 0px 150px 0px !important;
    }
    .blockContainer {
      max-width: unset !important;
      min-width: unset !important;
      margin: 0px !important;
      padding: 0px 15px !important;
    }
  }

  .blockContainer {
    max-width: 1300px;
    min-width: 800px;
    margin: 0 auto;
    padding: 0px 15px;
  }

  .wrapContent {
    padding : 0px 0px 0px 200px;
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  @keyframes upDown {
    from {
      transform : translate(10px, -10px) rotate(-10deg);
    }
    to {
      transform : translate(30px, 10px) rotate(0deg) ;
    }
  }
  @keyframes rotateCircle {
    0% {
      transform: rotate(0);
    }
  
    25% {
      transform: rotate(90deg);
    }
  
    50% {
      transform: rotate(180deg);
    }
  
    75% {
      transform: rotate(270deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
`

export default GlobalStyle
