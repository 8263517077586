import { memo } from "react";
import { SunIcon, MoonIcon } from "../Svg";
import { Toggle } from "../Toggle";

export interface Props {
  isDark: boolean;
  toggleTheme: (isDark: boolean) => void;
}

const ThemeSwitcher: React.FC<React.PropsWithChildren<Props>> = ({ isDark, toggleTheme }) => (
  <Toggle
    checked={isDark}
    defaultColor="color"
    checkedColor="color"
    scale="md"
    onChange={() => toggleTheme(!isDark)}
    startIcon={(isActive = false) => (
      <SunIcon width={14} height={14} color={isActive ? "colorReverse" : "colorReverse"} />
    )}
    endIcon={(isActive = false) => <MoonIcon width={14} height={14} color={isActive ? "color" : "color"} />}
  />
);

export default memo(ThemeSwitcher, (prev, next) => prev.isDark === next.isDark);
