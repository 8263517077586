import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  width: 150px;
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  padding: 0px 15px;
  width: 100%;
  height: 45px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.4s all;
  background: linear-gradient(to right, rgb(255, 204, 51), rgb(255, 179, 71));
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    @media screen and (max-width: 1199px) {
      justify-content: center;
      flex-direction: column-reverse;
    }
  }
  &:hover {
    filter: brightness(0.9);
  }
  p {
    margin: 0px 0px 0px 17.5px;
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.color : "#C1C1C1")};
    @media screen and (max-width: 1199px) {
      font-size: 1rem;
      line-height: calc(1rem * 1.25);
    }
  }
  svg {
    width: 16px;
    fill: ${({ theme, $isActive }) => ($isActive ? theme.colors.color : "#C1C1C1")};
  }
`;

export const StyledName = styled.div`
  user-select: none;
  p {
    font-family: SUISSE_BOLD;
    @media screen and (max-width: 1199px) {
      margin: 5px 0px -2px 0px;
    }
  }
`;

export const TextMenu = styled.div`
  color: #000;
  font-family: SUISSE_BOLD;
  font-size: 1.6rem;
  line-height: calc(1.6rem * 1.25);
  margin: 0px 0px 0px 5px;
`;

export default StyledMenuItem;
